import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./result.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Link from "react-router-dom";

import { IntlProvider } from "react-intl";
import { FormattedMessage } from "react-intl";
import { FormattedNumber } from "react-intl";
import { injectIntl } from "react-intl";
import PDF from "../pdf/pdf";
import Eng_doc from "../../assets/SPRINT_ENG.pdf";
import Fra_doc from "../../assets/SPRINT_FRA.pdf";
import Ita_doc from "../../assets/SPRINT_ITA.pdf";
import Slo_doc from "../../assets/SPRINT_SLO.pdf";
import Ger_doc from "../../assets/SPRINT_GER.pdf";
import axios from "axios";
import config from "../stepform/config.json";

import history from "../../lib/history";
import Form from "react-bootstrap/Form";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage_arr: [],
      heading_arr: JSON.parse(localStorage.getItem("heading_arr")),
      text: "",
      doc: "",
      email: "",
      message: "",
      submitmsg: "",
      error: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    window.scroll(0, 0);
    this.handleSectionResult();
    localStorage.removeItem("selected");
    localStorage.removeItem("selectedStep2");
    localStorage.removeItem("selectedStep3");
    localStorage.removeItem("langs");
    // localStorage.removeItem("heading_arr");
    switch (this.props.language) {
      case "en":
        this.setState({
          doc: Eng_doc,
        });
        break;
      case "fr":
        this.setState({
          doc: Fra_doc,
        });
        break;
      case "it":
        this.setState({
          doc: Ita_doc,
        });
        break;
      case "si":
        this.setState({
          doc: Slo_doc,
        });
        break;
      case "de":
        this.setState({
          doc: Ger_doc,
        });
        break;
      default:
        this.setState({
          doc: Eng_doc,
        });
        break;
    }
  }

  handleSectionResult = () => {
    let heading_arr = JSON.parse(localStorage.getItem("heading_arr"));
    let percentage_arr = this.state.percentage_arr;
    heading_arr.map((arr, index) => {
      console.log("index upper", index);
      percentage_arr.push((arr.count / arr.arr_length) * 100);
      this.setState({
        percentage_arr: percentage_arr,
      });
    });
    console.log("hello!", this.state.percentage_arr);
    const indexOfminValue = this.state.percentage_arr.indexOf(
      Math.min(...this.state.percentage_arr)
    );
    console.log("minimum", indexOfminValue);
    this.setState({
      text: heading_arr[indexOfminValue].heading,
    });
  };
  handleSubmit(event) {
    // alert("A name was submitted: " + this.state.value);
    event.preventDefault();
    console.info("email", this.state.email);

    if (this.state.email == "" || this.state.message == "") {
      this.setState({
        error: "Please fill both fields",
      });
      return false;
    }
    // this.props.history.push(`/test3`);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios({
      method: "post",
      url: mainURL + "comment/mail/post/result",
      data: {
        email: this.state.email,
        msg: this.state.message,
      },

      header: axiosConfig,
    }).then((response) => {
      this.setState(
        {
          submitmsg: "Your message has been submitted",
          email: "",
          message: "",
          error: "",
        },
        () => {
          console.log("arrya with amenity detil ", this.state.amenityDetail);
        }
      );
    });
    // this.props.history.push(`/test3`);
  }

  render() {
    let result_percent = parseFloat(
      (this.props.totalYes * 100) / this.props.totalresponse
    ).toFixed(2);
    localStorage.setItem("percent_result", result_percent);
    let styleobj = { background: "blue", fontSize: 64 };
    const { intl } = this.props;
    return (
      <React.Fragment>
        <section className="bg-grey pad-100 result">
          <div className="container">
            <div className="row">
              <div className="col-md-1  ">
                <div className="result-ticker">
                  <img
                    className=""
                    src={require("../../assets/Verified_Icon.png")}
                  />
                </div>
              </div>
              <div className="col-md-11">
                <h1 className="">
                  <FormattedMessage
                    id="result1"
                    defaultMessage="Congratulations for running the test"
                  />
                </h1>
                {result_percent >= 75 ? (
                  <p>
                    <FormattedMessage
                      id="result2"
                      defaultMessage="You have a great internship programme! You are very close to the level of the European quality criteria!"
                    />
                  </p>
                ) : result_percent >= 50 && result_percent < 75 ? (
                  <p>
                    <FormattedMessage
                      id="result3"
                      defaultMessage="You have good practices in place but should improve on some points to be at the level of the European quality criteria."
                    />
                  </p>
                ) : (
                  <p>
                    <FormattedMessage
                      id="result4"
                      defaultMessage="More effort is needed to be at the level of the European quality criteria. Building an excellent internship is not easy : have a look at the recommendations provided in the guide below."
                    />
                  </p>
                )}
              </div>
            </div>
            <div className="row mt-80">
              <div className="offset-md-1 col-md-2">
                <div className="result_percnt">
                  <h1>
                    <FormattedNumber
                      value={result_percent / 100}
                      maximumFractionDigits={2}
                      style="percent"
                    />
                  </h1>
                  <p>
                    <strong>
                      <FormattedMessage
                        id="result5"
                        defaultMessage="Your result"
                      />
                    </strong>
                  </p>
                </div>
              </div>
              <div className="col-md-9 bor-left">
                <div className="row reult-tool mb-30">
                  <div className="col-md-12">
                    {/* <p className="rating-text  ">Rating </p> */}
                    <div class="rating-text tooltip1">
                      <FormattedMessage id="result6" defaultMessage="Ratings" />
                      <span className="i-icon">i</span>
                      <span class="tooltiptext">
                        <FormattedMessage
                          id="result7"
                          defaultMessage="This rating is calculated based on your answers per section. Some section might have a different weight than others depending on the type and format of the questions."
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {JSON.parse(localStorage.getItem("heading_arr")).map(
                    (item, index) => (
                      <div className="col-md-6">
                        <p>{item.heading}</p>
                        <ProgressBar
                          now={parseFloat(
                            ((item.count / item.arr_length) * 100).toFixed(2)
                          )}
                          label={
                            <FormattedNumber
                              value={
                                ((item.count / item.arr_length) * 100).toFixed(
                                  2
                                ) / 100
                              }
                              maximumFractionDigits={2}
                              style="percent"
                            />
                          }
                        />
                      </div>
                    )
                  )}
                  {/* <div className="col-md-6">
                      <p>Supervision</p>
                        <ProgressBar now={10} />
                   </div><div className="col-md-6">
                      <p>Offer & contract</p>
                        <ProgressBar now={10} />
                   </div>
                  <div className="col-md-6">
                      <p>Work environment & Culture</p>
                        <ProgressBar now={10} />
                   </div><div className="col-md-6">
                      <p>Learning content</p>
                        <ProgressBar now={10} />
                   </div>
                  <div className="col-md-6">
                      <p>Career Development</p>
                        <ProgressBar now={10} />
                   
                   </div> */}

                  {/* <PDF /> */}
                  <a
                    onClick={() => localStorage.setItem("download", "true")}
                    href="/download"
                    target="_blank"
                  >
                    <button className="download-btn">
                      <FormattedMessage
                        id="result15"
                        defaultMessage="Download the results of the self-assessment (PDF)"
                      />
                    </button>
                  </a>
                  {/* {<PDFDownloadLink
        document={<Createpdf />}
        fileName="movielist.pdf"
        style={{
          textDecoration: "none",
          padding: "10px",
          color: "#4a4a4a",
          backgroundColor: "#f2f2f2",
          border: "1px solid #4a4a4a"
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Pdf"
        }
      </PDFDownloadLink>} */}
                  {/* <div className="col-md-12">
                    <a
                      href="../../assets/Verified_Icon.png"
                      download=""
                      className="download-btn"
                    ></a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pad-100">
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8 text-center ">
                <h1 className="main-heading mb-30">
                  <FormattedMessage
                    id="result16"
                    defaultMessage="Get the guide on quality internships"
                  />
                </h1>
                <p className="guide_qty_txt">
                  <FormattedMessage
                    id="result17"
                    defaultMessage="Based on your results, we suggest consulting the part on {name} in our host organisation guide for quality internships. It will help you improve your practices and be at the level of our European quality criteria"
                    values={{
                      name: <b> {this.state.text} </b>,
                    }}
                  />
                </p>
              </div>
              <div className="col-md-12 text-center">
                <a
                  href={this.state.doc}
                  download="Guide.pdf"
                  className="download-btn"
                >
                  <FormattedMessage
                    id="result18"
                    defaultMessage="Download the guide"
                  />
                </a>
              </div>
            </div>
            <div className="row mt-50">
              <div className=" col-md-12">
                <h1 className="main-heading mb-15">
                  <FormattedMessage
                    id="leaveComment"
                    defaultMessage="Leave a comment"
                  />
                </h1>
                <p className="guide_qty_txt testsho mb-30">
                  <FormattedMessage
                    id="shareComment"
                    defaultMessage="Share your comments and testimonials about your internship practices"
                  />
                </p>
              </div>
              <div className="col-md-5">
                {/* english */}
                {this.state.error && (
                  <span className="errormsg"> {this.state.error}</span>
                )}
                {this.state.submitmsg && (
                  <span className="successmsg"> {this.state.submitmsg}</span>
                )}
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="email"
                      value={this.state.email}
                      placeholder={intl.formatMessage({
                        id: "email",
                        defaultMessage: "Your Email",
                      })}
                      onChange={(e) =>
                        this.setState({
                          email: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      as="textarea"
                      id="mainInput"
                      rows="3"
                      value={this.state.message}
                      placeholder={intl.formatMessage({
                        id: "message",
                        defaultMessage: "Your message",
                      })}
                      onChange={(e) =>
                        this.setState({
                          message: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Button
                      variant="primary"
                      type="submit"
                      value={intl.formatMessage({
                        id: "sub",
                        defaultMessage: "Submit",
                      })}
                    >
                      <FormattedMessage id="sub" defaultMessage="Submit" />
                    </Button>
                    {/* <Form.Control type="submit" placeholder="Your Email" /> */}
                  </Form.Group>
                </Form>
                {/* english end */}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default injectIntl(Result);
