import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stepform.css";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Form } from "react-bootstrap";
import { Checkbox } from "semantic-ui-react";
import axios from "axios";
import config from "./config.json";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const now = 80;

class Step3 extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      tutoring: "",
      remunation: "",
      selected: [],
      start: false,
      all_headings: [],
      heading_arr: JSON.parse(localStorage.getItem("heading_arr")),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    console.info("strt", this.props.status);
    JSON.parse(localStorage.getItem("selectedStep3"))
      ? this.setState({
          selected: JSON.parse(localStorage.getItem("selectedStep3")),
        })
      : this.setState({});

    axios
      .get(mainURL + "/step3", {
        params: {
          user: localStorage.getItem("user"),
          lang: localStorage.getItem("langs") ? localStorage.getItem("langs") : 'en',
        },
      })
      .then((res) => {
        console.info(res.data);
        this.setState({
          all_headings: res.data,
          // selected: res.data.answers
        });
      });
    localStorage.setItem("all_heading", this.state.all_headings.length);
    console.info("answer list", this.state.selected);
  }

  legalbox(id, ans, question, heading_id, heading) {
    console.info("id", id);
    console.info("answor", ans);
    let tasks = this.state.selected;
    if (tasks.filter((item) => item.id == id).length == 0) {
      tasks.push({
        id: id,
        ans: ans,
        heading_id: heading_id,
        heading: heading,
        question: question,
      });
    } else {
      tasks.forEach((element, index) => {
        if (element.id === id) {
          tasks[index]["id"] = id;
          tasks[index]["ans"] = ans;
          tasks[index]["heading_id"] = heading_id;
          tasks[index]["heading"] = heading;
          tasks[index]["question"] = question;
        }
      });
    }
    this.setState({
      selected: tasks,
    });
    console.log("step 3", this.state.selected);
  }

  gotoPrvStp() {
    this.props.history.push(`/test2`);
  }
  handleSubmit(event) {
    // alert("A name was submitted: " + this.state.value);
    event.preventDefault();
    // this.props.history.push(`/test3`);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios({
      method: "post",
      url: mainURL + "step3/post/stepthree",
      data: {
        steponeans: this.state.selected,
        userid: localStorage.getItem("user"),
      },

      header: axiosConfig,
    }).then((response) => {
      if (response.status == "success") {
        this.props.history.push(`/result`);
      }
    });
    this.props.history.push(`/result`);
  }
  handleYesStep3 = () => {
    const { intl } = this.props;
    //for total 'yes' calculation
    let i = 0;
    this.state.selected.map((tutor) =>
      tutor.ans == intl.formatMessage({ id: "agree", defaultMessage: "Agree" })
        ? i++
        : ""
    );
    console.log("total yes in step 3", i);
    this.props.handleYesAndResponse3(i, this.state.selected.length);
    localStorage.setItem("selectedStep3", JSON.stringify(this.state.selected));
    // for section wise result calculation
    for (let i = 6; i < 6 + this.state.all_headings.length; i++) {
      let arr = this.state.heading_arr;
      let selected_arr = this.state.selected;
      const count = selected_arr.reduce(
        (acc, cur) =>
          cur.heading_id === i &&
          cur.ans ===
            intl.formatMessage({ id: "agree", defaultMessage: "Agree" })
            ? ++acc
            : acc,
        0
      );
      const total_count = selected_arr.reduce(
        (a, c) => (c.heading_id === i ? ++a : a),
        0
      );
      if (arr.filter((item) => item.heading_id == i).length == 0) {
        console.log("index is", arr);
        arr.push({
          heading_id: i,
          count: count,
          arr_length: total_count,
          heading: this.state.all_headings[i - 6].heading,
        });
      } else {
        arr.forEach((element, index) => {
          if (element.heading_id === i) {
            arr[i - 1]["heading_id"] = i;
            arr[i - 1]["count"] = count;
            arr[i - 1]["arr_length"] = total_count;
            arr[i - 1]["heading"] = this.state.all_headings[i - 6].heading;
          }
        });
      }
      this.setState({
        heading_arr: arr,
      });
      console.log("heading array for step2 is:", this.state.heading_arr);
    }
    localStorage.setItem("heading_arr", JSON.stringify(this.state.heading_arr));

    // for concating one object array to other one
    let selected_array = JSON.parse(localStorage.getItem("selected1"));
    var hash = {};
    (selected_array).forEach(function (a) {
      hash[a.question] = true;
    });
    (this.state.selected).forEach(function (a) {
        hash[a.question] || (selected_array).push(a);
    });
    
    console.log("selected array", selected_array);
    localStorage.setItem("selected1", JSON.stringify(selected_array));
  };

  render() {
    const { all_questions, all_headings } = this.state;
    const { intl } = this.props;

    return (
      <React.Fragment>
        <section className="progress-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ol className="progtrckr">
                  <li className="progtrckr-done" value="0">
                    <em>1</em>
                    <span>
                      <FormattedMessage
                        id="step1_1"
                        defaultMessage="Recruitment &amp; Written agreement"
                      />
                    </span>
                  </li>
                  <li className="progtrckr-done" value="1">
                    <em>2</em>
                    <span>
                      <FormattedMessage
                        id="step1_2"
                        defaultMessage="Remuneration, Skills, Tutoring"
                      />
                    </span>
                  </li>
                  <li className="progtrckr-doing" value="2">
                    <em>3</em>
                    <span>
                      <FormattedMessage
                        id="step1_3"
                        defaultMessage="Career, Work Environment, Social Protection"
                      />
                    </span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-30">
                <ProgressBar now={now} label={`${now}%`} />
              </div>
            </div>
          </div>
        </section>
        <section className="question-sec">
          <div className="container">
            <Form onSubmit={this.handleSubmit}>
              {all_headings.map((item, i) => (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="main-heading ques-heading">
                        {item.heading}
                      </h1>
                      <div className="hr-line"></div>
                    </div>
                  </div>
                  {item.questions.map((questionitem, i) =>
                    questionitem.status == 1 ? (
                      <div className="row">
                        <div className="col-md-7">
                          <p className="survey-question">
                            {questionitem.question}
                          </p>
                        </div>
                        <div className="col-md-5" key={i}>
                          <div className="answer-inputs">
                            <div className="ans-status">
                              <p>
                                {this.state.selected.find(
                                  (a) => a["id"] === questionitem.id
                                )
                                  ? this.state.selected.find(
                                      (a) => a["id"] === questionitem.id
                                    )["ans"]
                                  : questionitem.user_id == item.user &&
                                    questionitem.answer}
                              </p>
                            </div>
                            <div className="ans-selection like">
                              <input
                                title={intl.formatMessage({
                                  id: "agree",
                                  defaultMessage: "Agree",
                                })}
                                type="radio"
                                id="rec-q-1-like"
                                name={questionitem.id}
                                value="agree"
                                required
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({
                                      id: "agree",
                                      defaultMessage: "Agree",
                                    }),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                defaultChecked={
                                  questionitem.answer == 
                                  intl.formatMessage({
                                    id: "agree",
                                    defaultMessage: "Agree",
                                  })
                                    ? "checked"
                                    : ""
                                }
                                required
                              />
                              <i className="far fa-thumbs-up"></i>
                            </div>
                            <div className="ans-selection dislike">
                              <input
                                title={intl.formatMessage({
                                  id: "disagree",
                                  defaultMessage: "Disagree",
                                })}
                                type="radio"
                                id="rec-q-1-dislike"
                                name={questionitem.id}
                                value="disagree"
                                required
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({
                                      id: "disagree",
                                      defaultMessage: "Disagree",
                                    }),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                defaultChecked={
                                  questionitem.answer == 
                                  intl.formatMessage({
                                    id: "disagree",
                                    defaultMessage: "Disagree",
                                  })
                                    ? "checked"
                                    : ""
                                }
                                required
                              />
                              <i className="far fa-thumbs-down"></i>
                            </div>
                            <div className="ans-selection dnknow">
                              <input
                                title={intl.formatMessage({
                                  id: "idontknow",
                                  defaultMessage: "Don't know",
                                })}
                                type="radio"
                                id="other"
                                name={questionitem.id}
                                value="don't know"
                                required
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({
                                      id: "idontknow",
                                      defaultMessage: "Don't know",
                                    }),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                defaultChecked={
                                  questionitem.answer == 
                                  intl.formatMessage({
                                    id: "idontknow",
                                    defaultMessage: "Don't know",
                                  })
                                    ? "checked"
                                    : ""
                                }
                                required
                              />
                              <i className="fas fa-question"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-7">
                          <p className="survey-question">
                            {questionitem.question}
                          </p>
                        </div>
                        <div className="col-md-5" key={i}>
                          <div className="answer-inputs">
                            <div className="ans-status">
                              <p>
                                {this.state.selected.find(
                                  (a) => a["id"] === questionitem.id
                                )
                                  ? this.state.selected.find(
                                      (a) => a["id"] === questionitem.id
                                    )["ans"]
                                  : questionitem.user_id == item.user &&
                                    questionitem.answer}
                              </p>
                            </div>
                            <div className="ans-selection like">
                              <input
                                title={intl.formatMessage({
                                  id: "agree",
                                  defaultMessage: "Agree",
                                })}
                                type="radio"
                                id="rec-q-1-like"
                                name={questionitem.id}
                                value="agree"
                                required
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({
                                      id: "agree",
                                      defaultMessage: "Agree",
                                    }),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                              />
                              <i className="far fa-thumbs-up"></i>
                            </div>
                            <div className="ans-selection dislike">
                              <input
                                title={intl.formatMessage({
                                  id: "disagree",
                                  defaultMessage: "Disagree",
                                })}
                                type="radio"
                                id="rec-q-1-dislike"
                                name={questionitem.id}
                                value="disagree"
                                required
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({
                                      id: "disagree",
                                      defaultMessage: "Disagree",
                                    }),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                              />
                              <i className="far fa-thumbs-down"></i>
                            </div>
                            <div className="ans-selection dnknow">
                              <input
                                title={intl.formatMessage({
                                  id: "idontknow",
                                  defaultMessage: "Don't know",
                                })}
                                type="radio"
                                id="other"
                                name={questionitem.id}
                                value="don't know"
                                required
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({
                                      id: "idontknow",
                                      defaultMessage: "Don't know",
                                    }),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                              />
                              <i className="fas fa-question"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </React.Fragment>
              ))}
              <div className="row mt-50">
                <div className="col-md-12">
                  <div className="privacy-check">
                    <input type="checkbox" id="test2" required />
                    <label for="test2">
                      <FormattedMessage
                        id="step3_1"
                        defaultMessage="By accessing and using this site, I agree to be bound by the terms and conditions of use applying to the site and the proposed services, and the <a>privacy policy</a> relating to personal data protection"
                        values={{
                          a: (...chunks) => (
                            <a class="external_link" target="_blank" href="https://drive.google.com/file/d/14SD2SUcRLrwffJCMF1j3zly_nfmboc9f/view?usp=sharing ">
                              {chunks}
                            </a>
                          ),
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="offset-md-2  col-md-4">
                  <input
                    type="button"
                    class="pre-nxt-btn"
                    id="next-button"
                    value={intl.formatMessage({
                      id: "gotoprev",
                      defaultMessage: "Go to previous step",
                    })}
                    onClick={this.gotoPrvStp.bind(this)}
                  />
                </div>
                <div className="col-md-4">
                  <input
                    onClick={this.handleYesStep3}
                    type="submit"
                    class="pre-nxt-btn"
                    id="next-button"
                    value={intl.formatMessage({
                      id: "submit",
                      defaultMessage: "Submit the self-assessment",
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default injectIntl(withRouter(Step3));
