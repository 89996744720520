import React from 'react';
import { FormattedMessage } from "react-intl";
import { FormattedNumber } from "react-intl";
import "./pdf.css";
import history from '../../lib/history';

export default (props) => {
    const bodyRef = React.createRef();
    const createPdf = () => props.createPdf(bodyRef.current);
    return (
        <section className="pdf-container">
            <section className="pdf-toolbar">
                <a target="_blank"><button
                    onClick={() => {
                        createPdf();
                        history.push("/download");
                    }}
                    className="download-btn pdfbtn">
                    <FormattedMessage
                        id="result15"
                        defaultMessage="Download the results of the self-assessment (PDF)"
                    />
                </button>
                </a>
            </section>
            <section className="pdf-body" ref={bodyRef}>
                {props.children}
            </section>
        </section>
    )
}