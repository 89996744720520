import React, { Component } from "react";
import { Form, Radio } from 'semantic-ui-react';
import { injectIntl } from "react-intl";
import './dialog.css';

class Popup extends Component {
  state = {};
  handleChange = (e, { value }) => this.setState({ value });
  handleLabel = (e, { label }) => localStorage.setItem("label", label);
  handleClick = () => {
    this.props.toggle();
  };


  render() {
    const { intl } = this.props;
    return (
      <div>
        <div>
          {/* <span onClick={this.handleClick}>
            &times;
          </span> */}
          <Form>
            <Form.Field>
              <Radio
                label={intl.formatMessage({id: "radio1", defaultMessage: '1 tutor for 1 intern (1:1)'})}
                name="radioGroup"
                value="first"
                checked={this.state.value === "first"}
                onChange={this.handleChange}
                onClick={this.handleLabel}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label={intl.formatMessage({id: "radio2", defaultMessage: '1 tutor for 2 or 3 interns'})}
                name="radioGroup"
                value="second"
                checked={this.state.value === "second"}
                onChange={this.handleChange}
                onClick={this.handleLabel}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label={intl.formatMessage({id: "radio3", defaultMessage: '1 tutor for 4 or more interns'})}
                name="radioGroup"
                value="third"
                checked={this.state.value === "third"}
                onChange={this.handleChange}
                onClick={this.handleLabel}
              />
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}
export default injectIntl(Popup);
