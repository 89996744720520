import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './testimonials.css';
import Carousel from 'react-bootstrap/Carousel'
// import { Route, withRouter } from "react-dom";
import { IntlProvider } from "react-intl";
import { FormattedMessage } from "react-intl";
import French from '../languages/French';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
  }
    componentDidMount(
        
    ) {
      // axios.get(`/be/products/getAll.json`).then(res => {
      //     console.log('result', res.data)
      // })
    }

    render(){        
        return(
          <section className="bg-grey pad-100 testimonials">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <p className=" mb-30 p-32 fw-600">
                    <FormattedMessage
                    id="testimonial1"
                    defaultMessage="What host organisations say about our European quality criteria"
                    />
                  </p>
               </div>
                <div className="col-md-12">
                <Carousel indicators={false}>
  <Carousel.Item>
       <div className="row">
         
            <div className="col-md-4">
                <div className="testimonials-img text-center">
                    <img  className=""  src={require('../../assets/European_Youth_Forum.png')} alt="First slide"/>
                </div>
             </div>
             <div className="col-md-8 ">
                <div className="testimonials-test">
                <p className="testimonial-desc"><q>
                  <FormattedMessage
                  id="testimonial4"
                  defaultMessage={"Quality internships are an important learning experience for young people where they can gain practical workplace knowledge. These standards provide an excellent guideline for ensuring young people benefit from a quality experience where their rights are respected."}
                  />
                  </q></p>
                  {/* <h4 className="company_name">Microsoft</h4> */}
                  <p className="postion_name">
                  <FormattedMessage
                    id="testimonial2"
                    defaultMessage="European Youth Forum"
                    />
                  </p>
                </div>
             </div>
             
      </div>
    </Carousel.Item>
    {/* <Carousel.Item>
       <div className="row">
         
            <div className="col-md-4">
                <div className="testimonials-img text-center">
                    <img  className=""  src={require('../../assets/European_Youth_Forum.png')} alt="First slide"/>
                </div>
             </div>
             <div className="col-md-8 ">
                <div className="testimonials-test">
                      <p className="testimonial-desc"><q>
                      <FormattedMessage
                      id="testimonial4"
                      defaultMessage={"Quality internships are an important learning experience for young people where they can gain practical workplace knowledge. These standards provide an excellent guideline for ensuring young people benefit from a quality experience where their rights are respected."}
                      />
                      </q></p>
                      
                      <p className="postion_name">
                      <FormattedMessage
                      id="testimonial2"
                      defaultMessage="European Youth Forum"
                    />
                      </p>
                    </div>
             </div>
             
      </div>
    </Carousel.Item> */}
  
</Carousel>
                </div>
                
              </div>
              <div className="row">
              <div className="col-md-12">
                  <p className="mt-50 fnt-grey">
                  <FormattedMessage
                      id="testimonial3"
                      defaultMessage="The SPRINT project has been funded with support from the European Commission. This tool reflects the
                      views only of the author, and the Commission cannot be held responsible for any use which may be made of
                      the information contained therein."
                    /></p>
               </div>
              </div>
             
            </div>

          </section>
        
           
         
           
            
           
           
        );
    }
};
export default Testimonials;