import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
    createPdf = (html) => {
        savePDF(html, {
            paperSize: 'A3',
            fileName: 'Auto_assessment_result.pdf',
            margin: 3

        })
    }
}

const Doc = new DocService();
export default Doc;