import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';


class Footer extends React.Component {   
  render()
  {
  return (
    <React.Fragment>
      
      <div className="container-fluid footer">
        <div className="row">
           <div className="col-md-3 text-left">
             <div className="footer-img-left">
               <img className="" src={require('../../assets/sprint-white.png')} />
             </div>
           </div>
           <div className="col-md-6">
              <ul className="footer-link">
                <li className="mtt-10"><p>This work is licensed under a the following Creative Commons License: Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)</p></li>
                <li><a href="https://drive.google.com/file/d/14SD2SUcRLrwffJCMF1j3zly_nfmboc9f/view?usp=sharing" target="_blank">Privacy Policy</a></li>
              </ul>
           </div>
           <div className="col-md-3 text-right">
             <div className="footer-img-right">
               <img className="" src={require('../../assets/un2.jpg')} />
             </div>
           </div>
           
        </div>
      </div>
      
  
    </React.Fragment>
   
  );

}
}


export default Footer;