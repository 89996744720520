import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stepform.css";
import { Button, Form, FormControl } from "react-bootstrap";
import $ from "jquery";
import ProgressBar from "react-bootstrap/ProgressBar";
import { withRouter } from "react-router-dom";

const now = 100;

class Step4 extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      tutoring: "",
      remunation: "",
      status: false
    };
    this.gotofinal = this.gotofinal.bind(this);
  }
  componentDidMount() {
    // axios.get(`/be/products/getAll.json`).then(res => {
    //     console.log('result', res.data)
    // })
  }

  gotofinal() {
    this.props.history.push(`/result`);
  }

  render() {
    return (
      <React.Fragment>
          <section className="progress-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                      <ol class="progtrckr">
                        <li class="progtrckr-done" value="0">
                          <em>1</em>
                          <span>Recruitment &amp; Legal</span>
                        </li>
                        <li class="progtrckr-done" value="1">
                          <em>2</em>
                          <span>Remuneration, Skills, Tutoring</span>
                        </li>
                        <li class="progtrckr-done" value="2">
                          <em>3</em>
                          <span>Career, Work Environment, Social Protection</span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-30">
                      <ProgressBar now={now} label={`${now}%`} />
                    </div>
                  </div>
            </div>
        </section>
        <section className=" pad-65 text-center ">
          <div className="container">
          
            <div className="row">
              <div className="col-md-12 bg-grey pad-100">
                <h1 className="text-center">
                  Your test has been submitted successfully.
                </h1>
                <Button
                  className="result-btn"
                  onClick={this.gotofinal.bind(this)}
                >
                  See the result
                </Button>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(Step4);
