import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stepform.css";
import { withRouter } from "react-router-dom";
import axios from "axios";

// import '@fortawesome/fontawesome-free/css/all.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import $ from "jquery";
import { Form } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import config from "./config.json";
import { FormattedMessage} from 'react-intl';
import { injectIntl} from 'react-intl';

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const now = 5;
class Step1 extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      legalsection: "",
      selected: [],
      status: false,
      legalagree: false,
      all_headings: [],
      heading_arr: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    console.info("strt", localStorage.getItem("user"));
    JSON.parse(localStorage.getItem("selected"))
      ? this.setState({
          selected: JSON.parse(localStorage.getItem("selected")),
        })
      : this.setState({});

    JSON.parse(localStorage.getItem("heading_arr"))
      ? this.setState({
          heading_arr: JSON.parse(localStorage.getItem("heading_arr")),
        })
      : this.setState({});

    axios
      .get(mainURL, {
        params: {
          user: localStorage.getItem("user"),
          lang: localStorage.getItem("langs") ? localStorage.getItem("langs") : 'en',
        },
      })
      .then((res) => {
        console.info(res.data);
        this.setState({
          all_headings: res.data,
          // selected: res.data.answers
        });
      });
      localStorage.setItem("all_heading", this.state.all_headings.length);
    console.info("answer list", this.state.selected);
    console.info("answer list heading", this.state.heading_arr);
  }

  legalbox(id, ans, question, heading_id, heading) {
    const { intl } = this.props;
    console.info("id", id);
    console.info("answor", ans);
    console.info("quest", question);
    console.log("douhvcon", intl.formatMessage({id: "douhvcon", defaultMessage: "Do you have a contract or written agreement with your interns ?"}));
    console.log("agree/disagree", intl.formatMessage({id: "agree", defaultMessage: "Agree"}));
    if (
      question ===
      intl.formatMessage({id: "douhvcon", defaultMessage: "Do you have a contract or written agreement with your interns ?"})
         &&
      ans === intl.formatMessage({id: "agree", defaultMessage: "Agree"})
    ) {
      console.info("render if");
      this.setState({
        status: true,
        legalagree: true,
      });
      console.info("render if", this.state.status);
    }
    if (
      question ===
      intl.formatMessage({id: "douhvcon", defaultMessage: "Do you have a contract or written agreement with your interns ?"})
       &&
      (ans === intl.formatMessage({id: "disagree", defaultMessage: "Disagree"})
       || 
       ans === intl.formatMessage({id: "idontknow", defaultMessage: "Don't know"}))
    ) {
      console.info("ender if");

      this.setState({
        status: false,
        legalagree: false,
      });
    }
    let tasks = this.state.selected;
    if (tasks.filter((item) => item.id == id).length == 0) {
      tasks.push({
        id: id,
        ans: ans,
        heading_id: heading_id,
        heading: heading,
        question: question,
      });
    } else {
      tasks.forEach((element, index) => {
        if (element.id === id) {
          tasks[index]["id"] = id;
          tasks[index]["ans"] = ans;
          tasks[index]["heading_id"] = heading_id;
          tasks[index]["heading"] = heading;
          tasks[index]["question"] = question;
        }
      });
    }
    this.setState({
      selected: tasks,
    });
    console.log("status h", this.state.selected);
  }
  handleSubmit(e) {
    e.preventDefault();
    // var config = {
    //   headers: {'Access-Control-Allow-Origin': '*'}
    // }
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios({
      method: "post",
      url: mainURL + "/post/stepone",
      data: {
        steponeans: this.state.selected,
        userid: localStorage.getItem("user"),
      },

      header: axiosConfig,
    }).then((response) => {
      if (response.status == "success") {
        this.props.history.push(`/test2`);
      }
    });
    this.props.history.push(`/test2`);

    // alert("A name was submitted: " + this.state.value);
    console.info(e.target.value);
    // this.setState({
    //   start: true
    // });
    // if (this.state.start === true) {
    //   this.props.history.push(`/test2`);
    // }
  }

  handleTotalYes = () => {
    const { intl } = this.props;
    //for total 'yes' calculation
    let i = 0;
    this.state.selected.map((yestotal) => 
    (yestotal.ans == intl.formatMessage({id: "agree", defaultMessage: "Agree"}) ? i++ : ""));
    console.log("total yes ", i);
    this.props.handleYesAndResponse1(i, this.state.selected.length);
    localStorage.setItem("selected", JSON.stringify(this.state.selected));
    // for section wise result calculation

    for(let i =1; i <= this.state.all_headings.length; i++ ) {
      let arr = this.state.heading_arr;
      let selected_arr = this.state.selected;
      const count = selected_arr.reduce((acc, cur) => 
      cur.heading_id === i
      && cur.ans===intl.formatMessage({id: "agree", defaultMessage: "Agree"}) ? ++acc : acc, 0);
      const total_count = selected_arr.reduce((a, c) => c.heading_id === i ? ++a : a, 0);
      if (arr.filter(item => item.heading_id == i).length == 0) {
        arr.push({
          heading_id: i, 
          count: count, 
          arr_length: total_count, 
          heading: this.state.all_headings[i-1].heading
        });
      } else {
        arr.forEach((element, index) => {
          if (element.heading_id === i) {
            arr[index]["heading_id"] = i;
            arr[index]["count"] = count;
            arr[index]["arr_length"] = total_count;
            arr[index]["heading"] = this.state.all_headings[i-1].heading;
          }
        });
      }
       
      this.setState({
        heading_arr: arr,
      });
      console.log("heading array is:", this.state.heading_arr);
    }
    localStorage.setItem("heading_arr", JSON.stringify(this.state.heading_arr));
  };

  // handleSection = (heading_id) => {
  //   let arr = this.state.heading_arr;
  //   let selected_arr = this.state.selected;
  //     const count = selected_arr.reduce((acc, cur) => cur.heading_id === heading_id && cur.ans==="agree" ? ++acc : acc, 0);
  //     const total_count = selected_arr.reduce((a, c) => c.heading_id === heading_id ? ++a : a, 0);
  //     arr.push({heading_id: heading_id, count: count, arr_length: total_count});
  //   this.setState({
  //     heading_arr: arr
  //   });
  //   console.log("heading array is:", this.state.heading_arr);
  // }

  render() {
    const { all_questions, all_headings } = this.state;
    const { intl } = this.props;
    return (
      <React.Fragment>
        <section className="progress-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ol className="progtrckr">
                  <li className="progtrckr-doing" value="0">
                    <em>1</em>
                    <span>
                      <FormattedMessage
                      id="step1_1"
                      defaultMessage="Recruitment &amp; Written agreement"
                      />
                      </span>
                  </li>
                  <li className="progtrckr-todo" value="1">
                    <em>2</em>
                    <span>
                    <FormattedMessage
                      id="step1_2"
                      defaultMessage="Remuneration, Skills, Tutoring"
                    />
                    </span>
                  </li>
                  <li className="progtrckr-todo" value="2">
                    <em>3</em>
                    <span>
                    <FormattedMessage
                      id="step1_3"
                      defaultMessage="Career, Work Environment, Social Protection"
                    />
                    </span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-30">
                <ProgressBar now={now} label={`${now}%`} />
              </div>
            </div>
          </div>
        </section>
        <section className="question-sec">
          <div className="container">
            <Form onSubmit={this.handleSubmit}>
              {all_headings.map((item, i) => (
                <React.Fragment>
                  <div className="row ">
                    <div className="col-md-12">
                      <h1 className="main-heading ques-heading">
                        {item.heading}
                      </h1>
                      <div className="hr-line"></div>
                    </div>
                  </div>
                  {item.questions.map((questionitem, quetionidex) =>
                    questionitem.status == 1 ? (
                      <div className="row">
                        <div className="col-md-7">
                          <p className="survey-question">
                            {questionitem.question}
                            {/* {questionitem.answer} */}
                          </p>
                        </div>
                        <div className="col-md-5" key={quetionidex}>
                          <div className="answer-inputs">
                            <div className="ans-status">
                              <p>
                                {this.state.selected.find(
                                  (a) => a["id"] === questionitem.id
                                )
                                  ? this.state.selected.find(
                                      (a) => a["id"] === questionitem.id
                                    )["ans"]
                                  : questionitem.user_id == item.user &&
                                    questionitem.answer}
                              </p>
                            </div>
                            <div className="ans-selection like">
                              <input
                                type="radio"
                                id="rec-q-1-like"
                                title={intl.formatMessage({id: "agree", defaultMessage: "Agree"}) }
                                name={questionitem.id}
                                value="agree"
                                defaultChecked={
                                  questionitem.answer == intl.formatMessage({id: "agree", defaultMessage: "Agree"}) 
                                   &&
                                  questionitem.user_id == item.user
                                    ? "checked"
                                    : ""
                                }
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({id: "agree", defaultMessage: "Agree"}),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                required
                              />
                              <i className="far fa-thumbs-up"></i>
                            </div>
                            <div className="ans-selection dislike">
                              <input
                                type="radio"
                                id="rec-q-1-dislike"
                                title={intl.formatMessage({id: "disagree", defaultMessage: "Disagree"}) }
                                name={questionitem.id}
                                value="disagree"
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({id: "disagree", defaultMessage: "Disagree"}),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                defaultChecked={
                                  questionitem.answer == 
                                  intl.formatMessage({id: "disagree", defaultMessage: "Disagree"}) 
                                  &&
                                  questionitem.user_id == item.user
                                    ? "checked"
                                    : ""
                                }
                                required
                              />
                              <i className="far fa-thumbs-down"></i>
                            </div>
                            <div className="ans-selection dnknow">
                              <input
                                type="radio"
                                id="other"
                                title={intl.formatMessage({id: "idontknow", defaultMessage: "Don't know"}) }
                                name={questionitem.id}
                                value="don't know"
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({id: "idontknow", defaultMessage: "Don't know"}),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                defaultChecked={
                                  questionitem.answer == 
                                  intl.formatMessage({id: "idontknow", defaultMessage: "Don't know"}) 
                                   &&
                                  questionitem.user_id == item.user
                                    ? "checked"
                                    : ""
                                }
                                required
                              />
                              <i className="fas fa-question"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          this.state.status == true ? "row" : "row defaultnone"
                        }
                      >
                        {quetionidex === 1 && (
                          <div class="col-md-12 mt-50">
                            <p>
                              <strong>
                                <FormattedMessage
                                id="step1_4"
                                defaultMessage="Does the agreement include:"
                                />
                              </strong>
                            </p>
                          </div>
                        )}
                        <div className="col-md-7">
                          <p className="survey-question">
                            {questionitem.question}
                          </p>
                        </div>
                        <div className="col-md-5" key={i}>
                          <div className="answer-inputs">
                            <div className="ans-status">
                              <p>
                                {this.state.selected.find(
                                  (a) => a["id"] === questionitem.id
                                )
                                  ? this.state.selected.find(
                                      (a) => a["id"] === questionitem.id
                                    )["ans"]
                                  : questionitem.user_id == item.user &&
                                    questionitem.answer}
                              </p>
                            </div>
                            <div className="ans-selection like">
                              <input
                                type="radio"
                                id="rec-q-1-like"
                                title={intl.formatMessage({id: "agree", defaultMessage: "Agree"}) }
                                name={questionitem.id}
                                value="agree"
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({id: "agree", defaultMessage: "Agree"}),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                defaultChecked={
                                  questionitem.answer ==
                                  intl.formatMessage({id: "agree", defaultMessage: "Agree"})
                                   &&
                                  questionitem.user_id == item.user
                                    ? "checked"
                                    : ""
                                }
                                required={
                                  this.state.status == true ? "required" : ""
                                }
                              />
                              <i className="far fa-thumbs-up" title="Agree"></i>
                            </div>
                            <div className="ans-selection dislike">
                              <input
                                type="radio"
                                id="rec-q-1-dislike"
                                title={intl.formatMessage({id: "disagree", defaultMessage: "Disagree"}) }
                                name={questionitem.id}
                                value="disagree"
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({id: "disagree", defaultMessage: "Disagree"}),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                defaultChecked={
                                  questionitem.answer == 
                                  intl.formatMessage({id: "disagree", defaultMessage: "Disagree"})
                                   &&
                                  questionitem.user_id == item.user
                                    ? "checked"
                                    : ""
                                }
                              />
                              <i
                                className="far fa-thumbs-down"
                                title="Disagree"
                              ></i>
                            </div>
                            <div className="ans-selection dnknow">
                              <input
                                type="radio"
                                id="other"
                                title={intl.formatMessage({id: "idontknow", defaultMessage: "Don't know"}) }
                                name={questionitem.id}
                                value="don't know"
                                onClick={() => {
                                  this.legalbox(
                                    questionitem.id,
                                    intl.formatMessage({id: "idontknow", defaultMessage: "Don't know"}),
                                    questionitem.question,
                                    questionitem.heading_id,
                                    item.heading
                                  );
                                }}
                                defaultChecked={
                                  questionitem.answer == 
                                  intl.formatMessage({id: "idontknow", defaultMessage: "Don't know"})
                                   &&
                                  questionitem.user_id == item.user
                                    ? "checked"
                                    : ""
                                }
                              />

                              <i
                                className="fas fa-question"
                                title="Don't Know"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </React.Fragment>
              ))}
              <div className="row">
                <div className="offset-md-4 col-md-4">
                  <input
                    onClick={() => {
                      this.handleTotalYes();
                    }}
                    type="submit"
                    className="pre-nxt-btn"
                    id="next-button"
                    value={intl.formatMessage({id: "gotonext", defaultMessage: "Go to next step"}) }                  
                  />
                </div>
              </div>
            </Form>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default injectIntl(withRouter(Step1));
