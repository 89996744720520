import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stepform.css";
import $ from "jquery";
import StepZilla from "react-stepzilla";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import ProgressBar from "react-bootstrap/ProgressBar";

const now = 60;

const steps = [
  { name: "Recruitment & Legal", component: <Step1 /> },
  { name: "Remuneration, Skills, Tutoring", component: <Step2 /> },
  { name: "Career, Work Environment, Social Protection", component: <Step3 /> },
  { name: "Career, Work Environment, Social Protection", component: <Step4 /> },
];

class Stepform extends React.Component {
  componentDidMount() {
    // axios.get(`/be/products/getAll.json`).then(res => {
    //     console.log('result', res.data)
    // })
  }


  render() {
    return (
      <React.Fragment>
        <section className="pad-100">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-30">
                <ProgressBar now={now} label={`${now}%`} />
              </div>
              <div className="col-md-12">
                <div className="step-progress">
                  <StepZilla
                    steps={steps}
                    stepsNavigation={true}
                    nextButtonText="Go to next step"
                    backButtonText="Go to previous step"
                    nextTextOnFinalActionStep="Submit the test"
                    prevBtnOnLastStep={false}
                    showSteps={true}
                    showNavigation={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Stepform;
