import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./header.css";
import { MemoryRouter } from "react-router";
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import history from "../../lib/history";

import Info from "../info/info";
import Result from "../result/result";
import Stepform from "../stepform/stepform";
import Step1 from "../stepform/step1";
import Step2 from "../stepform/step2";
import Step3 from "../stepform/step3";
import Step4 from "../stepform/step4";
import Pdf from "../pdf/pdf";

import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
  Router,
} from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
const options = [
  { key: 1, text: "EN", value: "en" },
  { key: 2, text: "FR", value: "fr" },
  { key: 3, text: "IT", value: "it" },
  { key: 3, text: "SL", value: "si" },
  { key: 3, text: "DE", value: "de" },
];
const routes = [
  {
    path: "/",
    exact: true,
    main: Info,
  },
  {
    path: "/result",
    exact: true,
    main: Result,
  },
  {
    path: "/test",
    exact: true,
    main: Stepform,
  },
  {
    path: "/download",
    exaxt: true,
    main: Pdf,
  },
];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalYes1: 0,
      totalresponse1: 0,
      totalYes2: 0,
      totalresponse2: 0,
      totalYes3: 0,
      totalresponse3: 0,
      totalYes: 0,
      totalresponse: 0,
      status: "",
    };
  }
  handleYesAndResponse1 = (yes, response) => {
    console.log(`yes response1 : ${yes} and total- ${response}`);
    this.setState({
      totalYes1: yes,
      totalresponse1: response,
    });
  };
  handleYesAndResponse2 = (yes, response) => {
    console.log(`yes response2 : ${yes} and total- ${response}`);
    this.setState({
      totalYes2: yes,
      totalresponse2: response,
    });
  };
  handleYesAndResponse3 = (yes, response) => {
    console.log(`yes response3 : ${yes} and total- ${response}`);
    this.setState({
      totalYes3: yes,
      totalresponse3: response,
    });
  };

  handleStatus = (status) => {
    console.log("status", status);
    this.setState({
      status: status,
    });
  };

  handleDownload = () => {
    this.setState({
      download: true,
    });
  };

  render() {
    if (localStorage.getItem("download") == "true") {
      return (
        // <MemoryRouter history={history}>
        <BrowserRouter>
          <Route
            exact
            path="/download"
            render={(props) => <Pdf {...props} />}
          ></Route>
        </BrowserRouter>
      );
    }
    return (
      <MemoryRouter history={history}>
        <section className="header-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Navbar bg="" expand="lg">
                  <Navbar.Brand href="/">
                    {/* <img
                      className="brand-logo"
                      src={require("../../assets/Trusted-Jobs-Gradient.png")}
                    /> */}
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                      {/* <Nav.Link href="#home">Rate</Nav.Link> */}
                      {/* <Nav.Link href="#link">About Us</Nav.Link> */}
                    </Nav>
                    {/* <Form inline>
                      <button
                        className="lang-btn"
                        onClick={(e) => {
                          this.props.handleLocal("en");
                          e.preventDefault();
                        }}
                      >
                        En
                      </button>
                      <button
                        className="lang-btn"
                        onClick={(e) => {
                          this.props.handleLocal("fr");
                          e.preventDefault();
                        }}
                      >
                        Fr
                      </button>
                      <button
                        className="lang-btn"
                        onClick={(e) => {
                          this.props.handleLocal("it");
                          e.preventDefault();
                        }}
                      >
                        It
                      </button>
                      <button
                        className="lang-btn"
                        onClick={(e) => {
                          this.props.handleLocal("si");
                          e.preventDefault();
                        }}
                      >
                        Si
                      </button>
                      <button
                        className="lang-btn"
                        onClick={(e) => {
                          this.props.handleLocal("de");
                          e.preventDefault();
                        }}
                      >
                        Gr
                      </button>
                    </Form> */}
                    {this.state.status === "1" ? (
                      <Dropdown
                        disabled
                        selection
                        wrapSelection={false}
                        options={options}
                        onChange={this.props.handleLocal}
                        placeholder="EN"
                        className="lang-drop"
                      />
                    ) : (
                      <Dropdown
                        selection
                        wrapSelection={false}
                        options={options}
                        onChange={this.props.handleLocal}
                        placeholder="EN"
                        className="lang-drop"
                      />
                    )}
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </section>

        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Info handleStatus={this.handleStatus} />}
          ></Route>
          <Route
            exact
            path="/test"
            render={(props) => (
              <Step1
                {...props}
                handleYesAndResponse1={this.handleYesAndResponse1}
                language={this.props.language}
              />
            )}
          ></Route>
          <Route
            exact
            path="/test2"
            render={(props) => (
              <Step2
                {...props}
                handleYesAndResponse2={this.handleYesAndResponse2}
                language={this.props.language}
              />
            )}
          ></Route>
          <Route
            exact
            path="/test3"
            render={(props) => (
              <Step3
                {...props}
                handleYesAndResponse3={this.handleYesAndResponse3}
                language={this.props.language}
              />
            )}
          ></Route>
          <Route
            exact
            path="/test4"
            render={(props) => <Step4 {...props} />}
          ></Route>
          <Route
            exact
            path="/result"
            render={(props) => (
              <Result
                {...props}
                totalYes={
                  this.state.totalYes1 +
                  this.state.totalYes2 +
                  this.state.totalYes3
                }
                totalresponse={
                  this.state.totalresponse1 +
                  this.state.totalresponse2 +
                  this.state.totalresponse3
                }
                language={this.props.language}
              />
            )}
          ></Route>
        </Switch>
      </MemoryRouter>
    );
  }
}

export default Header;
