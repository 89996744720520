import React, { Component } from "react";
import "./pdf.css";
import Doc from "./DocService";
import PdfContainer from "./PdfContainer";
import { injectIntl } from "react-intl";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FormattedNumber } from "react-intl";
import { FormattedMessage } from "react-intl";
// import "@progress/kendo-theme-default/dist/all.css";
class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage_arr: [],
      heading_arr: JSON.parse(localStorage.getItem("heading_arr")),
      text: "",
      final_array1: [
        "Recruitment",
        "Written Agreement",
        "Remuneration",
        "Skills Development",
        "Tutoring",
        "Career Development and employment",
        "Work environment and working conditions",
        "Social protection and insurance",
      ],
    };
  }
  componentDidMount() {
    localStorage.removeItem("download");
    let selected_arr = JSON.parse(localStorage.getItem("selected1"));
    // JSON.parse(localStorage.getItem("selected1")) ?
    // selected_arr = JSON.parse(localStorage.getItem("selected1"))
    // :
    // ""
    let final_array = [
      "Recruitment",
      "Written Agreement",
      "Remuneration",
      "Skills Development",
      "Tutoring",
      "Career Development and employment",
      "Work environment and working conditions",
      "Social protection and insurance",
    ];
    console.info("headings array", final_array);
    let arr = [];
    let heading = [];
    for (var i = 0; i < final_array.length; i++) {
      arr.push(heading);
      for (var j = 0; j < selected_arr.length; j++) {
        if (final_array[i] === selected_arr[j].heading) {
          console.log("heading", selected_arr[j].heading);
          heading.push({
            question: selected_arr[j].question,
            ans: selected_arr[j].ans,
          });
        }
      }
    }
    console.log("final array", final_array);
    console.log("array", heading);
  }

  createPdf = (html) => Doc.createPdf(html);

  render() {
    let selected_arr = JSON.parse(localStorage.getItem("selected1"));
    let final_array = JSON.parse(localStorage.getItem("heading_arr"));
    const { intl } = this.props;
    // [
    //   "Recruitment",
    //   "Written Agreement",
    //   "Remuneration",
    //   "Skills Development",
    //   "Tutoring",
    //   "Career Development and employment",
    //   "Work environment and working conditions",
    //   "Social protection and insurance",
    // ];
    console.info("render headerr", final_array);
    const { final_array1, all_headings } = this.state;
    return (
      <React.Fragment>
        <PdfContainer createPdf={this.createPdf}>
          <React.Fragment>
            <section className=" pad-65 flex-column">
              <div className="container">
                <div className="row">
                  <div className="col-md-6  ">
                    <div className="info-img-left info-img text-left">
                      <img
                        className="pdfimgleft"
                        src={require("../../assets/logo_high.jpg")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6  ">
                    <div className="info-img-right info-img">
                      <img
                        className="pdfimg"
                        src={require("../../assets/cofoundedEU.png")}
                      />
                    </div>
                  </div>
                </div>

                {/* question answer end */}
                {/* graph area */}

                {/* graph area ends */}
                {/* question section title */}

                <div className="row">
                  <div className="col-md-12 mb-30">
                    <span className=" titstshow result-title ">
                      Result of your Auto - Assessment
                    </span>
                  </div>

                  <div className="row  mb-20">
                    <div className="col-md-2">
                      <div className="result_percnt rslt">
                        <h1>
                          <FormattedNumber
                            value={localStorage.getItem("percent_result") / 100}
                            maximumFractionDigits={2}
                            style="percent"
                          />
                        </h1>
                        <p>
                          <strong>
                            <FormattedMessage
                              id="result5"
                              defaultMessage="Your result"
                            />
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-9 bor-left">
                      <div className="row">
                        {JSON.parse(localStorage.getItem("heading_arr")).map(
                          (item, index) => (
                            <div className="col-md-6 mb-30">
                              <p>{item.heading}</p>
                              <ProgressBar
                                now={parseFloat(
                                  (
                                    (item.count / item.arr_length) *
                                    100
                                  ).toFixed(2)
                                )}
                                label={
                                  <FormattedNumber
                                    value={
                                      (
                                        (item.count / item.arr_length) *
                                        100
                                      ).toFixed(2) / 100
                                    }
                                    maximumFractionDigits={2}
                                    style="percent"
                                  />
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  {final_array.map((item, i) => (
                    <React.Fragment>
                      <div className="col-md-10">
                        <h2 className="result-title">{item.heading} </h2>
                      </div>
                      <div className="col-md-2">
                        <h2 className="result-title text-left">Answers </h2>
                      </div>

                      {selected_arr.map(
                        (value, i2) =>
                          item.heading === value.heading && (
                            <React.Fragment>
                              <div className="col-md-10 hw">
                                <p className="survey-question">
                                  {value.question}
                                </p>
                                <p>
                                  {(value.question ==
                                    "Garantissez-vous un ratio raisonnable de stagiaires par tuteur?" ||
                                    value.question ==
                                      "Assicurate un ragionevole rapporto di tirocinanti per tutor?" ||
                                    value.question ==
                                      "Ali zagotavljate primerno razmerje med pripravniki in mentorji?" ||
                                    value.question ==
                                      "Stellen Sie ein angemessenes Verhältnis von Praktikanten pro Tutor sicher?" ||
                                    value.question ==
                                      "Do you ensure a reasonable ratio of interns per tutor?") &&
                                    localStorage.getItem("label")}
                                </p>
                              </div>
                              <div className="col-md-2">
                                <p className="survey-question text-left">
                                  {value.ans}
                                </p>
                              </div>
                            </React.Fragment>
                          )
                      )}
                      <hr className="hr" />
                    </React.Fragment>
                  ))}
                </div>
                {/* questions-answer end*/}
              </div>
            </section>
          </React.Fragment>
        </PdfContainer>
      </React.Fragment>
    );
  }
}

export default injectIntl(Result);