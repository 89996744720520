import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './component/header/header';
import Info from './component/info/info';
import Starttest from './component/starttest/starttest';
import Testimonials from './component/testimonials/testimonials';
import Footer from './component/footer/footer';
import Result from './component/result/result';
import Stepform from './component/stepform/stepform';
import Test from './component/stepform/step1';

import { IntlProvider } from "react-intl";
import { FormattedMessage } from "react-intl";
import French from './component/languages/French';
import German from './component/languages/German';
import Italian from './component/languages/Italian';
import Solvene from './component/languages/Solvene';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      local: 'en',
    }
  }
  componentDidMount() {
    localStorage.removeItem("langs");
    localStorage.removeItem("selected1");
    localStorage.removeItem("user");
    localStorage.removeItem("percent_result");
    localStorage.removeItem("label");
    localStorage.removeItem("heading_arr");
    localStorage.removeItem("all_heading");
    localStorage.setItem("langs", this.state.local);

  }

  handleLocal = (event, data) => {
    console.info("lang change", data.value)
    this.setState({
      local: data.value
    });
    localStorage.setItem("langs", data.value);
  }

  render() {
    let lang;
    if (this.state.local === 'fr') {
      lang = French;
    } else
      if (this.state.local === 'de') {
        lang = German;
      } else
        if (this.state.local === 'it') {
          lang = Italian;
        } else
          if (this.state.local === 'si') {
            lang = Solvene;
          }

    return (
      <IntlProvider
        locale={this.state.local}
        messages={lang}
      >
        <React.Fragment>
          <Header
            handleLocal={this.handleLocal}
            language={this.state.local}
          />
          {/* <Test /> */}

          <Footer />
        </React.Fragment>
      </IntlProvider>
    );

  }
}
export default App;
