import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './info.css';
import Testimonials  from '../testimonials/testimonials';
import Starttest from '../starttest/starttest';
import { IntlProvider } from "react-intl";
import { FormattedMessage } from "react-intl";
import French from '../languages/French';


class Info extends React.Component {   
  constructor(props) {
    super(props);
    this.state={
    }
  }
  
  handleStatus = (status) => {
    console.log("status", status);
    this.props.handleStatus(status);
  }

  render() {
  return (
    <React.Fragment>
      <section className="bg-grey pad-100">
      <div className="container">
        <div className="row">
          <div className="offset-md-2 col-md-8 text-center">
              <h1 className="main-heading">
                <FormattedMessage
                id="startpage1"
                defaultMessage="Assess the quality of your internships"
                />
              </h1>
              <h2 className="sec-heading">
                <FormattedMessage 
                id="startpage2"
                defaultMessage="Find out how your internship programs compare with the European Quality Criteria and discover how to improve them"
                />
              </h2>
           </div>
           <div className="col-md-12 text-center"><p className="mt-80 p-32">
            <FormattedMessage
            id="startpage3"
            defaultMessage="With the support of"
            /> 
            </p></div>
           <div className="col-md-6  ">
             <div className="info-img-left info-img">
              <img className="" src={require('../../assets/sprint.png')} />
             </div>
           </div>
           <div className="col-md-6  ">
            <div className="info-img-right info-img">
               <img className="" src={require('../../assets/cofoundedEU.png')} />
            </div>
           </div>
        </div>
      </div>
      </section>
      <Starttest
      handleStatus={this.handleStatus}
      />
      <Testimonials/>
    </React.Fragment>
  );

}
}


export default Info;